<template>
  <Loader :value="promises.fetch" class="post-page">
    <Blocks v-if="item.posts" :blocks="config" :custom-components="customComponents" />
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CustomComponents from '@/components/Custom'
import { animate, inView } from 'motion'

import Config from '../../config/posts'
import { Blocks } from '@kvass/pagebuilder'

export default {
  props: {
    slug: {
      type: String,
      default: 'post',
    },
  },
  computed: {
    ...mapState('Root', ['promises', 'item']),
    config() {
      const sources = this.post.customFieldsConfig?.map(schema => schema.source) || ['subpage']
      return Config[sources[0]].call(this)
    },
    post() {
      if (!this.item.posts) return {}
      return this.item.posts.find(p => p.slug === this.slug)
    },
    customComponents() {
      return CustomComponents
    },
  },
  methods: {
    ...mapActions('Root', ['fetch']),
    async initAnimation() {
      inView(
        '.section-info',
        ({ target }) => {
          animate(target, { opacity: 1, transform: 'none' }, { duration: 0.8 })
        },
        { margin: '0px 0px -200px 0px' },
      )

      inView('.kvass-image-text__item', ({ target }) => {
        animate(target, { opacity: 1, transform: 'none' }, { duration: 0.8 })
      })
    },
  },
  created() {
    this.fetch()
  },
  mounted() {
    this.promises.fetch.then(() => this.$nextTick(() => this.initAnimation()))
  },

  metaInfo() {
    return {
      title: this.$path('title', this.post),
    }
  },

  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.post-page {
  .section-info {
    opacity: 0;
    transform: translateX(-5rem);
    @include respond-below('phone') {
      padding-top: 1rem;
    }
  }

  .kvass-image-text__item {
    opacity: 0;
  }
}
</style>
