export default function() {
  const get = (path, source = 'Root.item.posts') => {
    const slug = this.$path('_props.slug', this)
    if (!slug) return

    let data = this.$path(source, this.$store.state) || []
    if (source.includes('posts')) {
      data = data.find(p => p.slug === slug)
      if (!data) return
    }

    return this.$path(path, data)
  }

  return [
    {
      condition: get('media.cover.length') || get('content'),
      component: 'Section',
      props: {
        width: 'medium',
        id: 'section-info',
        class: 'section-info',
        style: 'font-size: 1.25rem;',
      },
      blocks: {
        default: [
          {
            component: 'Grid',
            props: {
              gap: '3rem',
              columns: '1',
            },
            blocks: {
              default: [
                {
                  component: 'Slider',
                  data: get('media.cover'),
                },
                {
                  component: 'Text',
                  props: { 'text-align': 'center', style: 'max-width: 620px; margin:0 auto' },
                  data: {
                    content: get('content'),
                  },
                },
              ],
            },
          },
        ],
      },
    },

    {
      condition: get('customFields.section'),
      component: 'Section',
      props: {
        width: 'medium',
        id: 'section-image-text',
        class: 'section-image-text',
      },
      blocks: {
        default: [
          {
            component: 'ImageText',
            data: {
              content: get('customFields.section'),
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      props: {
        width: 'full',
        id: 'section-map',
        class: 'section-map',
        style: 'padding-bottom:0;',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'map',
          },
          {
            component: 'Map',
            props: {
              aspectRatio: '21/9',
            },
            data: {
              ...get('address', 'Root.item'),
            },
          },
        ],
      },
      condition: get('customFields.page-settings.display-map'),
    },

    {
      component: 'Section',
      props: {
        width: 'medium',
        id: 'section-contact',
        class: 'section-contact',
        style: 'background-color: var(--light-grey);',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'lead',
          },
          {
            component: 'Grid',
            props: {
              gap: '2rem 20rem',
              columns: '1:2',
            },
            blocks: {
              default: [
                {
                  component: 'Roles',
                  props: {},
                  data: {
                    content: get('roles.salesman', 'Root.item'),
                  },
                },

                {
                  component: 'Lead',
                  props: {
                    options: {
                      placeholders: {
                        'contact.name': this.$t('name'),
                        'contact.email': this.$t('email'),
                        'contact.phone': this.$t('phone'),
                        comment: this.$tc('message', 1),
                      },
                      fields: ['!title', '!contact.upsell'],
                      actionLabel: this.$t('send'),
                    },
                  },
                  data: {
                    project: get('id', 'Root.item'),
                    reference: { onModel: 'Project', ref: get('id', 'Root.item') },
                  },
                  blocks: {
                    top: [
                      {
                        component: 'Text',
                        data: {
                          content: get('customFields.lead-top-content', 'Root.item'),
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ]
}
